.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 1rem;
}

.contact-title {
  font-size: 2rem;
  font-weight: bold;
  margin: 2rem 0;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
}

.contact-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
}

.contact-label {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.contact-input {
  padding: 0.5rem;
  border: none;
  border-bottom: 2px solid #e2e8f0;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  width: 100%;
  transition: border-color 0.2s ease-in-out;
}

.contact-input:focus {
  outline: none;
  border-color: #3182ce;
}

.contact-button {
  background-color: #3182ce;
  color: #fff;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 1rem;
}

.contact-button:hover {
  background-color: #4b9cdb;
}

.contact-info {
  font-size: 1.2rem;
  margin-top: 2rem;
  text-align: center;
}